<template>
	<div>
		<el-form :inline="true" :model="form">
			<el-form-item label="设备编号">
				<el-input v-model="form.deviceNo" placeholder="请输入设备编号"></el-input>
			</el-form-item>
			<el-form-item label="设备类型">
				<el-select v-model="form.deviceTypeCode" placeholder="请选择设备类型">
					<el-option label="" value=""></el-option>
					<el-option v-for=" ( item , i ) in typeList " :key="i" :label="item.typeName" :value="item.typeCode"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="设备品牌">
				<el-input v-model="form.deviceBrand" placeholder="请输入设备品牌"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button @click="device_list()" type="primary">查询</el-button>
			</el-form-item>
		</el-form>
		<el-row class="mg_b text_r">
			<el-button type="primary" icon="el-icon-download" @click="device_export()" style="margin-right: 10px;">模板</el-button>
			<el-button type="primary" icon="el-icon-upload" @click="more_device()" style="margin-right: 10px;">导入</el-button>
			<el-button type="primary" icon="el-icon-plus" @click="edit_monitoring( 0 )" circle></el-button>
		</el-row>
		<el-table :data="list" border style="width: 100%" v-loading="loading">
			<el-table-column prop="deviceNo" label="设备编号" ></el-table-column>
			<el-table-column prop="deviceId" label="设备ID" ></el-table-column>
			<el-table-column prop="iccid" label="ICCID" width="220" >
				<template slot-scope="scope">
					<el-tag size="mini" :type="scope.row.iccid == '' ? 'info' : ''" disable-transitions>{{scope.row.iccid == '' ? '—' : scope.row.iccid}}</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="typeName" width="120" label="设备类型"></el-table-column>
			<el-table-column prop="deviceBrand" width="120" label="设备品牌" ></el-table-column>
			<el-table-column prop="createTime" label="创建时间" ></el-table-column>
			<el-table-column prop="modifyTime" label="最后修改时间">
				<template slot-scope="scope">
					<el-tag size="mini" :type="scope.row.modifyTime == null ? 'info' : ''" disable-transitions>{{scope.row.modifyTime == null ? '—' : scope.row.modifyTime}}</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="140">
			    <template slot-scope="scope" v-if="scope.row.deviceTypeCode != 6">
					<el-button size="mini" icon="el-icon-edit" type="primary" @click="edit_monitoring( 1 , scope.row )" plain style="margin-right: 10px;">编辑</el-button>
					<el-popconfirm title="是否要删除？" icon-color="red" @confirm="del_monitoring( scope.row.id )" >	
						<el-button slot="reference" size="mini" type="danger" icon="el-icon-delete" plain>删除</el-button>
					</el-popconfirm>
			    </template>
			</el-table-column>
		</el-table>
		<div class="page mg_t text_c">
			<el-pagination 
				@current-change="changePage"
				@size-change="sizeChange"
				:page-sizes="[ 10 , 20 , 30 , 40]" 
				:total="total" 
				:currentPage="form.startPage" 
				:pageSize="form.pageSize" 
				background
				layout="total, sizes, prev, pager, next, jumper"
			>
			</el-pagination>
		</div>
		<edit ref="edit" @success="device_list" />
		<upload ref="upload" @success="device_list" />
	</div>
</template>

<script>
	import edit from "./components/edit.vue";
	import upload from "./components/upload.vue";
	var fileDownload = require('js-file-download');
	export default{
		data(){
			return {
				loading: false,
				form : {
					deviceNo : "",
					startPage : 1,
					pageSize : 10,
					deviceId : "",
					deviceTypeCode : "",
					deviceBrand : ""
				},
				total : 0,
				list : [],
				//地址
				typeList:[],
				upload:[]
			}
		},
		created(){
			//设置默认查询地址
			this.device_list();
		},
		mounted(){
			this.init_getTypeList();
		},
		methods: {
			init_getTypeList(){
				this.$api.device_getTypeList().then(res => {
					if (res.code == 200) {
						this.typeList = res.data
					}else{
						this.$message({
							type: 'error',
							message: "获取设备类型失败"
						});
					}
				});
			},
			device_list(){
				this.loading = true;
				this.$api.device_list( this.form ).then( res => {
					if( res.code == 200 ){
						this.list = res.data.list;
						this.form.startPage = res.data.page;
						this.total = res.data.totalNum;
						this.loading = false;
						for( var i = 0 ; i < this.list.length ; i++ ){
							// 报警器设备-0:报警 1:正常 非报警器设备-0:在线-关机 1:在线-开机 -1:在线 -2:离线)
							if( this.list[i].deviceTypeCode == 0 ){
								if( this.list[i].status == 0 ){
									this.list[i].statusText = "报警"
								}else if( this.list[i].status == 1 ){
									this.list[i].statusText = "正常"
								}else{
									this.list[i].statusText = "—"
								}
							}else{
								if( this.list[i].status == 0 ){
									this.list[i].statusText = "在线-关机"
								}else if( this.list[i].status == 1 ){
									this.list[i].statusText = "在线-开机"
								}else if( this.list[i].status == -1 ){
									this.list[i].statusText = "在线"
								}else if( this.list[i].status == -2 ){
									this.list[i].statusText = "离线"
								}else{
									this.list[i].statusText = "—"
								}
							}
						}
					}else{
						this.list = [];
						this.form.startPage = 1;
						this.total = 0;
						this.loading = false;
					}
				});
			},
			edit_monitoring( type , data ){
				this.$refs.edit.type = type;
				this.$refs.edit.open( data ? data : "" );
			},
			del_monitoring( id ){
				this.$api.device_delete( { id } ).then( res => {
					this.$message({
						type: res.code == 200 ? 'success' : 'error',
						message: res.message
					});
					if( res.code == 200 ){
						if( this.list.length == 1 && this.form.startPage > 1 ){
							this.form.startPage = this.form.startPage - 1;
						}
						this.device_list();
					}
				});
			},
			changePage( e ){
				this.form.startPage = e;
				this.device_list();
			},
			sizeChange( e ){
				this.form.pageSize = e;
				this.device_list();
			},
			device_export(){
				this.$api.device_downloadTemplate().then( res => {
				  fileDownload( res , "新增设备模板.xls" );
				});
			},
			more_device(){
				this.$refs.upload.open();
			}
			
		},
		components:{
			edit,upload
		}
	}
</script>
